import React from 'react';

const PaginationEllipsis = () => {
	return (
		<li className="pagination-ellipsis">
			<span>&hellip;</span>
		</li>
	);
};

export default PaginationEllipsis;
