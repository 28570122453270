import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPage } from '../utilities/paging';

const PaginationPrevious = ({ paging }) => {
	const { widgetName, windowId } = useSelector((state) => state.requestData);
	const previous =
		paging.pageStart <= 0
			? null
			: Math.max(paging.pageStart - paging.pageSize, 0);
	const prevStart = paging.pageStart - paging.pageSize;
	const href = `${window.location.pathname}?start=${prevStart}`;

	return previous !== null ? (
		<li className="pagination-previous" key="previous">
			<a
				aria-label="Go to previous page"
				href={href}
				onClick={(e) =>
					getPage(e, prevStart, 'previous', widgetName, windowId)
				}
				title="View previous page of matching results"
			>
				<i
					className="ddc-icon ddc-icon-arrow1-left"
					aria-hidden="true"
				/>
			</a>
		</li>
	) : null;
};

PaginationPrevious.propTypes = {
	paging: PropTypes.shape({
		pageStart: PropTypes.number.isRequired,
		pageSize: PropTypes.number.isRequired,
		totalCount: PropTypes.number.isRequired
	}).isRequired
};

export default PaginationPrevious;
