import React from 'react';
import PropTypes from 'prop-types';

const PagingContainer = (props) => {
	return (
		<div className="d-flex justify-content-between">
			<div
				className="compare-selected"
				id="compare-content-buttons-portal"
			/>
			{props.children}
		</div>
	);
};

export default PagingContainer;

PagingContainer.propTypes = {
	children: PropTypes.node
};

PagingContainer.defaultProps = {
	children: null
};
