// opted against using react-bootstrap v3 (not maintained) pagination because of issues with a11y

import React, { useState, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { getSortedPagination, isPagingNeeded } from '../utilities/paging';
import PaginationItem from '../components/PaginationItem';
import PaginationNext from '../components/PaginationNext';
import PaginationPrevious from '../components/PaginationPrevious';
import PaginationEllipsis from '../components/PaginationEllipsis';
import PagingContainer from '../components/PagingContainer';
import { shouldHidePagingBecauseOfOffSiteData } from '../utilities/offSiteUtils';
import { useInventory } from '../utilities/waitForInvData';

const getOffSiteSessionStorage = () => {
	return (
		(typeof sessionStorage !== 'undefined' &&
			sessionStorage.getItem('showOffSite') &&
			true) ||
		false
	);
};

const Widget = () => {
	const [showOffSite, setShowOffSite] = useState(getOffSiteSessionStorage());
	const { isLoading, isError, paging } = useInventory();

	useEffect(() => {
		if (isBrowser && window?.DDC?.pubsub) {
			window.DDC.pubsub.subscribe(
				'ws-inv-listing/showOffSiteInventory',
				() => {
					setShowOffSite(true);
				}
			);
		}
	}, []);

	if (isLoading || isError) {
		return null;
	}

	const { offSiteData } = window?.DDC?.InvData?.getFacets() || {};

	// case for when paging shouldn't render
	// PagingContainer still needs to be rendered by this widget in this case because
	// compare buttons in ws-inv-listing use a portal to be placed at the bottom of the page.
	if (
		!isPagingNeeded(paging) ||
		shouldHidePagingBecauseOfOffSiteData(offSiteData, paging, showOffSite)
	) {
		return <PagingContainer />;
	}

	const currentPage = Math.floor(paging.pageStart / paging.pageSize);
	const sorted = getSortedPagination(currentPage, paging);
	const pages = [];

	sorted.forEach((value, i) => {
		pages.push(
			<PaginationItem
				key={value}
				currentPage={currentPage}
				pageIndex={value}
				pageNumber={value + 1}
				pageSize={paging.pageSize}
			/>
		);
		// Add ellipsis when there is a gap between pages
		if (sorted[i + 1] && value + 1 !== sorted[i + 1]) {
			pages.push(<PaginationEllipsis key={`ellipsis-${value}`} />);
		}
	});

	return (
		<ErrorBoundary
			errorHandler={(error, errorInfo) => {
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-paging error boundary.\n${error}`
				);
				const newError = new Error(
					`ws-inv-paging error boundary.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			<PagingContainer>
				<nav aria-label="Navigation for matching results pages">
					<ul className="pagination">
						<PaginationPrevious paging={paging} />
						{pages}
						<PaginationNext paging={paging} />
					</ul>
				</nav>
			</PagingContainer>
		</ErrorBoundary>
	);
};

export default Widget;
