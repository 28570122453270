import { useEffect, useReducer } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';

const invDataReducer = (state, action) => {
	switch (action.type) {
		case 'INV_DATA_INIT':
			return {
				...state,
				isLoading: true,
				isError: false
			};
		case 'INV_DATA_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				paging: action.payload
			};
		case 'INV_DATA_FAILURE':
		default:
			return {
				...state,
				isLoading: false,
				isError: true,
				paging: action.payload
			};
	}
};

export const useInventory = () => {
	const pubsub = isBrowser && window.DDC?.pubsub;
	const [state, dispatch] = useReducer(invDataReducer, {
		isLoading: true,
		isError: false,
		paging: null
	});

	// wait for DDC.InvData
	useEffect(() => {
		(async () => {
			let waitForInvDataHandle;
			dispatch({ type: 'INV_DATA_INIT' });
			const checkForInvData = () => {
				if (window.DDC?.InvData?.srpReady) {
					pubsub.subscribe('ws-inv-data/inventory', () => {
						dispatch({
							type: 'INV_DATA_SUCCESS',
							payload: window.DDC.InvData.getPaging()
						});
					});
					pubsub.subscribe(
						'ws-inv-data/inventory/request-failed',
						() => {
							dispatch({ type: 'INV_DATA_FAILURE' });
						}
					);
					dispatch({
						type: 'INV_DATA_SUCCESS',
						payload: window.DDC.InvData.getPaging()
					});
				}
				clearInterval(waitForInvDataHandle);
			};
			waitForInvDataHandle = setInterval(checkForInvData, 100);
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return state;
};
