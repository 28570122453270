import { trackEvent } from 'ddc-track-event';
/**
 * Prevents event, sets the start value, triggers a tracking event and scrolls
 * the user to the top of the page.
 * @param {object} e An event object
 * @param {number} start A number defining the next start value to be set
 * @param {string} type A string representing the type of paging button
 * @param {string} widgetName A string for widgetName
 * @param {string} windowId A string for windowId
 * @param {number} pageNumber (optional) A number to represent specific page
 */
export const getPage = (e, start, type, widgetName, windowId, pageNumber) => {
	e.preventDefault();
	window.DDC.InvData.setStart(start);
	trackEvent(widgetName, windowId, {
		element: `Pagination ${type} button`,
		elementCTA: type === 'specific' ? pageNumber : undefined,
		result: `Navigated to ${type} page`
	});
	window.DDC.scrollToAnchor($('[data-widget-id="inventory-results1"]'), {
		heightAdj: $('.page-header').height()
	});
};

/**
 * Sorts ascending and removes negative numbers or numbers above the last page.
 * @param {number} currentPage A number representing the current page index
 * @param {object} paging An object consisting of paging data
 */
export const getSortedPagination = (currentPage, paging) => {
	const lastPage = Math.floor((paging.totalCount - 1) / paging.pageSize);
	const pagination = new Set([
		0,
		currentPage - 1,
		currentPage,
		currentPage + 1,
		lastPage
	]);

	switch (currentPage) {
		case 0: // Show up to the 3rd page when currentPage is 0
			pagination.add(currentPage + 2);
			break;
		case 3: // Never show ellipsis in place of a number between the previous and next
			pagination.add(1);
			break;
		case lastPage - 4:
			pagination.add(lastPage - 1);
			break;
		case lastPage: // Show third to last page when currentPage is the last page
			pagination.add(lastPage - 2);
			break;
		default:
			break;
	}

	return [...pagination]
		.sort((a, b) => {
			return a - b;
		})
		.filter((x) => x >= 0 && x <= lastPage);
};

/**
 * return true if paging.pageStart is 0.
 * @param paging
 * @returns {boolean}
 */
export const isFirstPage = (paging) => {
	return paging ? paging?.pageStart === 0 : false;
};

/**
 * return true if paging is needed.
 * Paging isn't needed if there is no inventory (paging.totalCount === 0)
 * or if the inventory count is less than the number of inventory that shows on a single page
 *
 * @param paging
 * @returns {boolean}
 */
export const isPagingNeeded = (paging) => {
	return (
		paging &&
		paging.totalCount !== 0 &&
		paging.totalCount >= paging.pageSize
	);
};
