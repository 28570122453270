import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPage } from '../utilities/paging';

const PaginationNext = ({ paging }) => {
	const { widgetName, windowId } = useSelector((state) => state.requestData);
	const nextPage = paging.pageStart + paging.pageSize;
	const next = nextPage > paging.totalCount - 1 ? null : nextPage;
	const nextStart = paging.pageStart + paging.pageSize;
	const href = `${window.location.pathname}?start=${nextStart}`;

	return next !== null ? (
		<li className="pagination-next" key="next">
			<a
				aria-label="Go to next page"
				href={href}
				onClick={(e) =>
					getPage(e, nextStart, 'next', widgetName, windowId)
				}
				title="View next page of matching results"
			>
				<i
					className="ddc-icon ddc-icon-arrow1-right"
					aria-hidden="true"
				/>
			</a>
		</li>
	) : null;
};

PaginationNext.propTypes = {
	paging: PropTypes.shape({
		pageStart: PropTypes.number.isRequired,
		pageSize: PropTypes.number.isRequired,
		totalCount: PropTypes.number.isRequired
	}).isRequired
};

export default PaginationNext;
