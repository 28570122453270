import { isFirstPage } from './paging';

/**
 * return true if offSiteData is defined WITH offSiteBannerPosition and offSiteItemCount,
 * and if either offSiteBannerPosition is not -1 or offSiteItemCount is not -1
 *
 * offSiteBannerPosition: index of first offSite inventory, starting at zero
 * offSiteItemCount: number of offSite inventory
 *
 * If both offSiteBannerPosition and offSiteItemCount are -1 that means the offSite feature is turned off
 * If something is wrong in WIS or 'offSiteData' is broken in some other way, this function will return false.
 * @param offSiteData
 * @returns {boolean}
 */
export const isOffSiteToggleEnabled = (offSiteData) => {
	return offSiteData &&
		typeof offSiteData.offSiteBannerPosition !== 'undefined' &&
		typeof offSiteData.offSiteItemCount !== 'undefined'
		? !!(
				offSiteData?.offSiteBannerPosition !== -1 ||
				offSiteData?.offSiteItemCount !== -1
		  )
		: false;
};

/**
 * return true if offSiteToggle is enabled and there is more than a page worth of onsite vehicles.
 * In this case, paging is required to reach the offsite banner and subsequent inventory.
 *
 * Keep in mind, offSiteBannerPosition = index of first offSite inventory, starting at zero.
 * PageSize is not offset in this way. A pageSize of 10 = 10 pieces of inventory on the page.
 * @param offSiteData
 * @param paging
 * @returns {boolean}
 */
export const doesOnSiteInventoryRequirePaging = (offSiteData, paging) => {
	return (
		isOffSiteToggleEnabled(offSiteData) &&
		paging &&
		(offSiteData.offSiteBannerPosition === -1 ||
			offSiteData.offSiteBannerPosition >= paging.pageSize)
	);
};

/**
 * returns true if isOffSiteToggleEnabled is true, if showOffSiteState is false,
 * if doesOnSiteInventoryRequirePaging is false, and if isFirstPage is true. Hope this helps.
 * @param offSiteData
 * @param paging
 * @param showOffSiteState
 */
export const shouldHidePagingBecauseOfOffSiteData = (
	offSiteData,
	paging,
	showOffSiteState
) => {
	return (
		isOffSiteToggleEnabled(offSiteData) &&
		!showOffSiteState &&
		!doesOnSiteInventoryRequirePaging(offSiteData, paging) &&
		isFirstPage(paging)
	);
};
