import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPage } from '../utilities/paging';

const PaginationItem = ({ currentPage, pageIndex, pageNumber, pageSize }) => {
	const { widgetName, windowId } = useSelector((state) => state.requestData);
	const itemStart = pageIndex * pageSize;
	const href = `${window.location.pathname}?start=${itemStart}`;
	const isCurrent = pageIndex === currentPage;
	const active = isCurrent ? 'active' : null;
	const ariaCurrent = isCurrent ? 'page' : null;

	return (
		<li className={active}>
			<a
				aria-current={ariaCurrent}
				href={href}
				key={pageIndex}
				onClick={(e) =>
					getPage(
						e,
						itemStart,
						'specific',
						widgetName,
						windowId,
						pageNumber
					)
				}
				aria-label={`Go to results page ${pageNumber}`}
				title={
					isCurrent
						? `Currently viewing page ${pageNumber} of matching results`
						: `Go to page ${pageNumber} of matching results`
				}
			>
				{pageNumber}
			</a>
		</li>
	);
};

PaginationItem.propTypes = {
	currentPage: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired,
	pageNumber: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired
};

export default PaginationItem;
